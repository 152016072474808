<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="invoiceData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap justify-left w-full">
          <div class="py-2" style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-2"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="py-2" style="float: left">
            <vs-select
              placeholder="All"
              autocomplete
              vs-multiple
              v-model="dataTableParams.month"
              class="mr-2"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in monthOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="py-2" style="float: left">
            <vs-select
              placeholder="Year"
              autocomplete
              vs-multiple
              v-model="dataTableParams.year"
              class="mr-2"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in yearOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="py-2" style="float: right">
            <vs-button
              id="button-with-loading"
              class="vs-con-loading__container"
              @click="exportCsvHandler"
              :disabled="invoiceData.length <= 0"
              >Export</vs-button
            >
            <a
              id="downloadCustomerCsv"
              ref="downloadCustomerCSVRef"
              :href="customerCSVurl"
            ></a>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="invoiceNumber">Invoice Number</vs-th>
          <vs-th sort-key="clinicName">Clinic Name</vs-th>
          <vs-th>Year</vs-th>
          <vs-th>Month</vs-th>
          <vs-th>Cost $</vs-th>
          <vs-th>Sent On</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].invoiceNumber">
              {{ data[indextr].invoiceNumber }}
            </vs-td>
            <vs-td :data="data[indextr].clinicName">
              <div v-if="data[indextr].clinicName">
                {{ data[indextr].clinicName | capitalize }}
              </div>
              <div v-else>N/A</div>
            </vs-td>
            <vs-td :data="data[indextr].year">
              {{ data[indextr].year }}
            </vs-td>
            <vs-td :data="data[indextr].month">
              {{ data[indextr].month }}
            </vs-td>
            <vs-td :data="data[indextr].totalAmount">
              {{ (data[indextr].totalAmount / 100) | currency }}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">
              {{ data[indextr].createdAt | date_formatter }}
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <div
                style="
                  display: flex;
                  justify-content: left;
                  align-items: center;
                "
              >
                <div v-if="data[indextr].pdfLink">
                  <vx-tooltip text="View Invoices" position="top">
                    <a
                      :href="data[indextr].pdfLink"
                      :download="getName(data, indextr)"
                      target="_blank"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="
                          vs-component vs-button
                          m-1
                          vs-button-primary vs-button-border
                          includeIcon
                          includeIconOnly
                          small
                        "
                        style="background: transparent; float: left"
                        type="border"
                        size="small"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </div>
                <div
                  v-if="
                    ['Upcoming', 'Hold', 'Failed'].includes(
                      data[indextr].paidStatus
                    )
                  "
                >
                  <vx-tooltip text="View Invoice Details" position="top">
                    <vs-button
                      type="border"
                      size="small"
                      @click="viewInvoiceDetail(data[indextr]._id)"
                      icon-pack="feather"
                      icon="icon-list"
                      class="mr-2"
                    ></vs-button>
                  </vx-tooltip>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import ClinicYearMonthPopup from "./ClinicYearMonthPopup";
import moment from "moment";
import Vue2Filters from "vue2-filters";

export default {
  components: {
    ClinicYearMonthPopup,
  },
  mixins: [Vue2Filters.mixin],
  props: {
    clinicId: {
      type: String,
      default: undefined,
    },
    year: {
      type: Number,
      default: undefined,
    },
    month: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      noDataText: "No invoice available",
      rerenderKey: 0,
      customerCSVurl: false,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        activeClinicId: "",
        clinicId: this.clinicId,
        year: "",
        month: "",
        status: "",
      },
      serverResponded: false,
      invoiceData: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      monthOptions: [
        { text: "All Months", value: "" },
        { text: "January", value: "Jan" },
        { text: "February", value: "Feb" },
        { text: "March", value: "Mar" },
        { text: "April", value: "Apr" },
        { text: "May", value: "May" },
        { text: "June", value: "Jun" },
        { text: "July", value: "Jul" },
        { text: "August", value: "Aug" },
        { text: "September", value: "Sep" },
        { text: "October", value: "Oct" },
        { text: "November", value: "Nov" },
        { text: "December", value: "Dec" },
      ],
      yearOptions: [],
      statusOptions: [
        { text: "All", value: "all" },
        { text: "Paid", value: "Succeeded" },
        { text: "Hold", value: "Hold" },
        { text: "Failed", value: "Failed" },
        { text: "Processing", value: "Processing" },
        { text: "Upcoming", value: "Upcoming" },
      ],
      awaitingSearch: null,
      popUpPay: false,
      popUpError: false,
      popUpErrorMessage: "",
      invoiceDataSelectedToPay: {
        invoiceNumber: "",
        invoiceId: "",
        invoiceAmount: "",
        invoiceYear: "",
        invoiceMonth: "",
      },
    };
  },
  methods: {
    ...mapActions("general", [
      "fetchAdminClinicInvoice",
      "payAdminClinicInvoice",
      "deleteInvoiceDataFromDb",
      "exportClinicInvoiceCsv",
    ]),
    moment(date) {
      return moment(date);
    },
    async getInvoiceList() {
      let self = this;
      this.$vs.loading();
      if (self.dataTableParams.status == "all")
        self.dataTableParams.status = "";

      await this.fetchAdminClinicInvoice(self.dataTableParams)
        .then((res) => {
          this.$vs.loading.close();
          self.invoiceData = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total;
          self.page = res.data.data.pagination.page;
          self.serverResponded = true;
          self.noDataText = "No invoice available";
        })
        .catch((err) => {
          console.log("This is the error ::", err);
        });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getInvoiceList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getInvoiceList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getInvoiceList();
    },
    forceRerender() {
      this.rerenderKey += 1;
    },
    invoiceSelectedToPay(invoice, clinicId) {
      this.invoiceDataSelectedToPay = {
        invoiceNumber: invoice.invoiceNumber,
        invoiceId: invoice._id,
        invoiceAmount: invoice.totalAmount / 100,
        invoiceYear: invoice.year,
        invoiceMonth: invoice.month,
      };
      this.popUpPay = true;
    },
    cancelInvoiceSelectedToPay() {
      this.invoiceDataSelectedToPay = {
        invoiceNumber: "",
        invoiceId: "",
        invoiceAmount: "",
        invoiceYear: "",
        invoiceMonth: "",
      };
      this.popUpPay = false;
    },
    getName(data, indextr) {
      return `${data[indextr].invoiceNumber} ${data[indextr].clinicName}`;
    },
    payInvoice() {
      const self = this;
      self.$vs.loading();
      self
        .payAdminClinicInvoice(self.invoiceDataSelectedToPay.invoiceId)
        .then((result) => {
          self.$vs.notify({
            title: "Success",
            text: "Invoice paid successfully",
            color: "success",
          });

          self.cancelInvoiceSelectedToPay();
          self.$vs.loading.close();
        })
        .catch((err) => {
          self.cancelInvoiceSelectedToPay();
          if (err.status === 420) {
            self.popUpError = true;
            self.popUpErrorMessage = err.data.message;
          } else {
            self.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger",
            });
          }
          self.$vs.loading.close();
        })
        .finally(() => {
          self.forceRerender();
        });
    },
    deleteInvoiceData() {
      this.$vs.loading();
      this.deleteInvoiceDataFromDb()
        .then((result) => {
          this.$vs.notify({
            title: "Success",
            text: "Successfully flushed clinic invoice table",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.log(err);
          this.$vs.notify({
            title: "Error",
            text: err.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        })
        .finally(() => {
          this.forceRerender();
        });
    },
    async exportCsvHandler() {
      this.$vs.loading({
        background: "primary",
        color: "#fff",
        container: `#button-with-loading`,
        scale: 0.45,
      });

      if (this.dataTableParams.status == "all")
        this.dataTableParams.status = "";

      await this.exportClinicInvoiceCsv(this.dataTableParams)
        .then(async (res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "freshclinic-clinic-invoice.csv");
          document.body.appendChild(link);
          link.click();
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Failed",
            text: "Failed to export list",
            color: "danger",
          });
          console.log(err);
        });
    },
    viewInvoiceDetail(id) {
      this.$emit("viewInvoiceDetailUpcoming", id);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getInvoiceList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getInvoiceList();
      }
    },
    "dataTableParams.year": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.year = newVal;
        this.getInvoiceList();
      }
    },
    "dataTableParams.month": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.month = newVal;
        this.getInvoiceList();
      }
    },
    "dataTableParams.status": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.status = newVal;
        this.getInvoiceList();
      }
    },
    rerenderKey: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getInvoiceList();
      }
    },
    "$store.state.AppActiveClinicId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.activeClinicId = newVal;
        this.getInvoiceList();
      }
    },
    clinicId: function (newVal, oldVal) {
      if (newVal !== undefined && newVal !== oldVal) {
        this.dataTableParams.clinicId = newVal;
        this.getInvoiceList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  async created() {
    this.dataTableParams.activeClinicId = (await this.$store.state
      .AppActiveClinicId)
      ? this.$store.state.AppActiveClinicId
      : "all";
    // Setting year filter option
    const maxYear = moment(new Date()).format("YYYY");
    const limitYear = moment(new Date()).subtract(30, "years").format("YYYY");
    for (let i = maxYear; i >= limitYear; i--) {
      const year = i.toString();
      const obj = {
        text: year,
        value: year,
      };
      this.yearOptions.push(obj);
    }
    this.dataTableParams.year = this.yearOptions[0].value;
    this.getInvoiceList();
  },
};
</script>

<style scoped></style>
